function setEqualHeight() {
    $(".column-about-us").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
    $(".grid-menu-item-020 .grid-element-title-wrapper").matchHeight();
    $(".grid-menu-item-020").matchHeight();
    $(".grid-menu-item-021 .grid-element-title-wrapper").matchHeight();
    $(".grid-menu-item-021").matchHeight();
    $(".grid-menu-item-021-001 .grid-element-title-wrapper").matchHeight();
    $(".grid-menu-item-021-001").matchHeight();
    $(".grid-news-item-023 .article-image-wrapper").matchHeight();
    $(".grid-news-item-023 .article-title").matchHeight();
    $(".grid-news-item-023 .article-content-wrapper").matchHeight();


}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').on("change",function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height",maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height","");
            $menu.css("height","");
        });
    }
});


$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 0,
        scroll_limit = 140,
        initial_height = 140,
        target_height = 80,
        initial_padding = 35,
        target_padding = 10,
        initial_opacity = 0.8,
        target_opacity = 1,
        logo_height,
        padding,
        line_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 140;
            initial_height = 140;
            target_height = 80;
            initial_padding = 35;
            target_padding = 10;
            break;
        case "xxlarge":
        scroll_limit = 140;
        initial_height = 140;
        target_height = 80;
        initial_padding = 35;
        target_padding = 10;
            break;
        case "large":
        scroll_limit = 120;
        initial_height = 120;
        target_height = 80;
        initial_padding = 25;
        target_padding = 10;
            break;
        case "medium":
        scroll_limit = 80;
        initial_height = 80;
        target_height = 80;
        initial_padding = 10;
        target_padding = 10;
            break;
        case "small":
        scroll_limit = 70;
        initial_height = 70;
        target_height = 70;
        initial_padding = 10;
        target_padding = 10;
            break;
        case "xsmall":
        scroll_limit = 60;
        initial_height = 60;
        target_height = 60;
        initial_padding = 10;
        target_padding = 10;
            break;
        default:
        scroll_limit = 140;
        initial_height = 140;
        target_height = 80;
        initial_padding = 35;
        target_padding = 10;
            break;
    }
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() === 0) {
            padding = initial_padding;
            logo_height = initial_height;
            opacity = initial_opacity;
        } else if ($(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            // padding = initial_padding * ((scroll_limit - scroll_limit_1) - ($(window).scrollTop() - scroll_limit_1)) / (scroll_limit - scroll_limit_1);
            padding = initial_padding - (initial_padding - target_padding) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            logo_height = initial_height - (initial_height - target_height) * ($(window).scrollTop() - scroll_limit_1) / (scroll_limit - scroll_limit_1);
            opacity = initial_opacity + (($(window).scrollTop()- scroll_limit_1)/(scroll_limit - scroll_limit_1))  * (target_opacity - initial_opacity);
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").show();
                $("#logo-scroll").hide();
            }
            */
        } else {
            padding = target_padding;
            logo_height = target_height;
            opacity = target_opacity;
            /*
            if (current_breakpoint_num > 3) {
                $("#logo-top").hide();
                $("#logo-scroll").show();
            }
            */
        }
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        $("#body-page-1 #header-wrapper").css("background-color", "rgba(7, 32, 52, " + opacity + " )");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        $("#body-page-1 #header-wrapper").css("background-color", "rgba(7, 32, 52, " + opacity + " )");
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});

/*
var rellax = new Rellax('.rellax');
*/
